$(function () {
    $('[data-warn]').on('click', (e) => {
        if (!window.confirm('Weet je het zeker?')) e.preventDefault()
    })
    $('[data-price]').each(
        (key, el) =>
            new Cleave(el, {
                numeral: true,
                numeralThousandsGroupStyle: 'none',
                numeralDecimalScale: 2,
                numeralDecimalMark: ',',
                numeralPositiveOnly: true,
                prefix: '€ ',
                signBeforePrefix: true,
                stripLeadingZeroes: true,
                onValueChanged: (e) =>
                    $(`[name="${el.dataset.price}"]`).val(
                        e.target.value.slice(2).replace(',', '.'),
                    ),
            }),
    )
    $('[data-int]').each(
        (key, el) =>
            new Cleave(el, {
                numeral: true,
                numeralThousandsGroupStyle: 'none',
                numeralDecimalScale: 0,
                stripLeadingZeroes: true,
            }),
    )
    if ($('[data-arrangement]').length) {
        $('[data-arrangement]')
            .on('change', (e) => {
                $('[data-id]').each((key, el) => {
                    $(el).toggleClass('disabled', $(el).data('id') != e.target.value)
                })
            })
            .trigger('change')
    }
    $('.sortable').sortable({
        update: function (event, ui) {
            var order = []
            $(this)
                .find('*[data-id]')
                .each(function () {
                    order.push($(this).data('id'))
                })
            $('input[name=order]').val(JSON.stringify(order))
        },
    })
    $('[data-submit]').on('click', function () {
        $('form').submit()
    })
    if ($('[data-conditional]').length) {
        function change() {
            $('[data-conditional]').each(function () {
                var key = $(this).data('key')
                var value = $(this).data('value')
                var el = $(`[name=${key}]`)
                var actual = el.is(':radio')
                    ? $(`[name=${key}]:checked`).val()
                    : $(`[name=${key}]`).val()
                $(this).toggleClass('visible', actual == value)
            })
        }
        $('input, textarea, select').on('change', change)
        change()
    }
})
